body {
	background-color: #e1f4f3;
	background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
}

.page-container {
	position: relative;
	min-height: 100vh;
	max-height: auto;
	padding-bottom: 10rem; /*Footer height */
}

.pageTitle {
	font-family: "Michroma", sans-serif;
	color: #0c7b93;
	text-align: center;
	margin-bottom: 2rem;
	background-image: url("https://www.transparenttextures.com/patterns/low-contrast-linen.png");
	background-size: contain;
	padding-top: 5px;
	padding-bottom: 5px;
}

/* NAVIGATION BAR */
.navbar {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	margin-bottom: 2rem;
}
.navbar .navbar-nav {
	float: none;
	margin: 0 auto;
	display: flex;
	text-align: center;
	font-size: large;
}

.navbar-nav > li {
	padding-left: 30px;
	padding-right: 30px;
	font-family: "Kanit", sans-serif;
	font-size: 20px;
}

.nav-link:hover {
	cursor: pointer;
	background: #dbdbdb;
}
.logo:hover {
	cursor: pointer;
}

.brandTitle {
	font-family: "Lobster", sans-serif;
	font-size: 45px;
	color: #3282b8 !important;
	font-weight: 900;
}

/* HOME  */
.home {
	text-align: center;
	font-size: 3rem;
	font-family: "Play", sans-serif;
	text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
		0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
		0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
		0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
		0 20px 20px rgba(0, 0, 0, 0.15);
	letter-spacing: 1px;
	color: #52de97;
	padding-top: 70px;
}

.home h1 {
	font-size: 4rem;
}

/* ABOUT */

.aboutCard {
	background-color: #e6f8f909;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.25);
	border-radius: 2rem;
	border: 3px solid #f1f3f4;
}

.picture {
	width: 100%;
	/* border: 1px solid rgba(201, 169, 30, 0.562); */
	border-radius: 4px;
	padding-bottom: 30px;
}

.about p {
	font-size: 20px;
	font-family: "Montserrat", sans-serif;
}

/* CONTACT */
.contact {
	color: #488b8f;
	font-family: "Montserrat", sans-serif;
}

.my-info {
	padding-top: 70px;
	text-align: center;
}

/* RESUME */
.resume {
	font-family: "Montserrat", sans-serif;
}

.writing h5 {
	margin-top: 10px;
}

.resume h3 {
	font-family: "Archivo Black", sans-serif;
	text-decoration: underline;
}
.iconresume {
	width: 60px;
	height: 30px;
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 10px;
}

.resumebtn {
	margin-top: 50px;
	border-radius: 10px;
}

.resumebtn:hover {
	border-radius: 20px;
	letter-spacing: 1px;
}

/* PORTFOLIO */

.portContent {
	font-family: "Montserrat", sans-serif;
}

.portContent h5 {
	font-weight: bold;
}

.cardFooter {
	display: flex;
	justify-content: space-around;
}

.toolsUsed {
	font-weight: normal;
	font-size: small;
	font-style: italic;
}

/* FOOTER */
.footer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	border-radius: 1rem;
	background-color: #cae8d5;
	opacity: 0.85;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 6px;
	font-family: "Lato", sans-serif;
}

.footer a {
	color: black;
	font-size: 18px;
}

.footer a:hover {
	text-decoration: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23453886' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 15%;
	border-bottom: 0;
	padding-bottom: 0.3em;
	text-decoration: none;
}
